//! css framework
// @import "../node_modules/bulma/bulma";

// //! utilities
@import "./styles/utils/mixins"; // global mixins
@import "./styles/utils/variables"; // global variables
@import "../node_modules/open-color/open-color.scss";

// //! setup
@import "./styles/setup/normalize"; // normalize browsers to make more consistent
@import "./styles/setup/clearfix"; // clearfix for floats
// @import "./styles/setup/border-box"; // global border-box setting

// //! global styles
// @import "./styles/global/typography"; // global typography
// @import "./styles/global/global"; // global styles

// //! sections
// @import "./styles/layout/header";

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$min_width: 320px;
$max_width: 1200px;
$min_font: 50px;
$max_font: 150px;

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.title {
  display: block;
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  text-transform: uppercase;
  font-weight: 700;
  // position: absolute;
	// top: 50%;
	left: 0;
	width: 100%;
	text-align: center;
	// margin: -5.5vw 0 0 0;
	z-index: 100;
	// font-size: 10vw;
	// color: var(--color-title);
	color: white;
  // line-height: 1;
  mix-blend-mode: exclusion;
}
